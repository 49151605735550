.login-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: linear-gradient(
    to left top,
    #b2eeff,
    #8ed0e9,
    #6cb3d4,
    #4c96bf,
    #2a7aaa
  );
}

.login-main .login-card {
  width: 30%;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 50px 10px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
}

.login-card .icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  color: blue;
}

@media (max-width: 600px) {
  .login-main .login-card {
    width: 70%;
  }
}
