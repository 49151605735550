* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  padding: 10px;
}

/* .title {
  font-size: 3rem;
  display: grid;
  place-items: center;
} */

/* Sidebar */
.sidebar {
  background: rgb(29, 29, 29);
  color: white;
  /* height: 100%; */
  overflow-y: auto;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}
.logo {
  font-size: 18px;
  line-height: 0;
}
.bars {
  width: 30px;
}
.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}
.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);

  color: white;
}

.routes {
  /* margin-top: 15px; */
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
}
.link {
  display: flex;
  column-gap: 10px;
  color: white;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link_text {
  white-space: nowrap;
  font-size: 15px;
  font-family: sans-serif !important;
}

.link {
  display: flex;
  align-items: center;
}

.menu {
  display: flex;
  color: white;

  /* padding: 5px 10px; */
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu_item {
  display: flex;
  /* gap: 10px; */
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}

.menu-item {
  /* border: 1px solid white; */
  margin: 6px 9px !important;
  border-radius: 5px;
  text-decoration: none;
}

.main-container {
  /* padding: 1em 1em; */
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100vh;
}

.app-sidebar {
  flex-grow: 0;
  flex-shrink: 0;
  /* min-width: 150px; */
  /* max-width: 300px; */
  display: flex;
  border-right: #e9e9e9 1px solid;
  flex-direction: row;
  background: #ffffff;
  box-shadow: -8px 2px 22px -7px rgba(0, 0, 0, 0.25);
  border-radius: 10px 0px 0px 10px;
  z-index: 2;
}

.app-frame {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: white;
  height: 100vh;
  max-height: 100%;
  background: #ffffff;
  box-shadow: 8px 2px 32px -2px rgba(0, 0, 0, 0.25);
  border-radius: 0px 10px 10px 0px;
  z-index: 1;
}

.app-sidebar .app-sidebar-content {
  flex: 1;
}

.app-sidebar .app-sidebar-resizer {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 6px;
  justify-self: flex-end;
  cursor: col-resize;
  resize: horizontal;
}

.app-sidebar .app-sidebar-resizer:hover {
  width: 3px;
  background: #c1c3c5b4;
}

.sidebar-main-sec {
  overflow-x: auto !important;
}
