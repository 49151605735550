body {
  margin: 0;
  font-family: Arial, sans-serif;
}

nav {
  background-color: rgba(0, 0, 0, 0.888);
  height: 3.5rem;
}
.logo {
  padding: 7px 15px;
}
